import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1279.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,600.000000) scale(0.100000,-0.100000)">
          <path d="M1675 4140 l-1270 -1270 1260 -1261 c693 -693 1268 -1265 1278 -1270
16 -9 187 158 1290 1260 l1271 1272 -1269 1269 c-699 699 -1274 1270 -1280
1270 -6 0 -581 -571 -1280 -1270z m2509 -49 l1220 -1220 -1221 -1220 c-671
-672 -1224 -1221 -1227 -1221 -12 0 -2436 2428 -2436 2439 0 13 2421 2439
2435 2440 6 1 559 -548 1229 -1218z"/>
<path d="M2570 4081 l0 -591 100 0 100 0 2 243 3 242 205 3 c113 2 220 0 238
-3 35 -6 105 -61 97 -76 -3 -4 -18 -3 -33 3 -17 7 -116 13 -259 16 l-233 4 0
-602 0 -603 213 6 c291 7 384 35 499 150 108 109 148 230 148 452 0 184 -28
293 -101 397 -15 20 -19 44 -19 107 0 102 -25 172 -86 239 -24 28 -44 54 -44
59 0 5 10 29 23 54 18 37 22 60 22 144 0 87 -3 107 -25 150 -32 64 -102 134
-165 165 -50 25 -53 25 -367 28 l-318 3 0 -590z m609 371 c75 -38 95 -160 36
-221 -45 -48 -66 -52 -260 -49 l-180 3 -3 129 c-1 72 0 136 2 143 4 10 47 13
188 13 155 0 188 -3 217 -18z m60 -667 c72 -19 78 -27 41 -58 -40 -34 -70 -39
-215 -35 l-120 3 -1 45 c0 25 1 48 3 53 7 13 235 7 292 -8z m229 -212 c6 -16
16 -46 21 -68 14 -51 14 -306 0 -364 -27 -118 -100 -214 -198 -260 -52 -24
-66 -26 -199 -26 l-142 0 -1 315 0 315 163 5 c150 5 167 7 218 32 30 14 69 38
85 52 38 32 40 32 53 -1z"/>
<path d="M1550 2140 l0 -210 85 0 c135 0 195 35 195 115 0 44 -23 92 -49 100
-17 5 -17 8 10 35 51 51 33 127 -37 156 -20 8 -68 14 -119 14 l-85 0 0 -210z
m203 138 c21 -25 16 -65 -10 -85 -10 -7 -45 -13 -80 -13 l-63 0 0 58 c0 32 3
62 6 65 4 4 34 4 68 2 47 -4 65 -10 79 -27z m-16 -154 c44 -12 55 -111 15
-133 -26 -14 -107 -24 -131 -16 -19 6 -21 14 -21 81 l0 74 58 0 c31 0 67 -3
79 -6z"/>
<path d="M1979 2326 c-55 -38 -74 -85 -74 -186 0 -72 4 -92 25 -129 31 -54 78
-81 144 -81 62 0 109 28 139 84 33 61 31 194 -3 254 -33 58 -74 82 -141 82
-41 0 -63 -6 -90 -24z m142 -32 c17 -8 37 -30 45 -49 19 -46 18 -177 -1 -215
-17 -32 -61 -60 -95 -60 -34 0 -78 28 -95 60 -23 45 -20 187 4 224 36 55 87
69 142 40z"/>
<path d="M2359 2327 c-75 -50 -59 -149 29 -184 25 -10 68 -29 96 -43 49 -24
51 -27 51 -65 0 -47 -25 -65 -90 -65 -43 0 -95 36 -95 65 0 9 -10 15 -25 15
-19 0 -25 -5 -25 -23 0 -52 75 -97 160 -97 90 0 148 50 136 118 -8 51 -49 88
-125 113 -65 21 -111 56 -111 85 0 8 12 27 26 41 22 22 32 25 70 20 49 -5 84
-30 84 -59 0 -13 7 -18 29 -18 28 0 29 1 21 31 -5 17 -15 38 -21 46 -40 48
-152 59 -210 20z"/>
<path d="M2670 2140 l0 -210 135 0 c128 0 135 1 135 20 0 19 -7 20 -105 20
l-105 0 0 80 0 80 90 0 c83 0 90 1 90 20 0 19 -7 20 -90 20 l-90 0 0 71 c0 67
1 70 23 69 12 -1 59 -2 104 -1 71 1 81 3 79 19 -3 15 -18 17 -134 20 l-132 3
0 -211z"/>
<path d="M3010 2140 l0 -210 68 0 c89 0 139 15 178 54 37 37 54 89 54 161 -2
140 -72 204 -222 205 l-78 0 0 -210z m190 141 c35 -25 52 -65 56 -135 7 -106
-39 -163 -136 -168 l-55 -3 -3 155 c-1 85 0 161 3 168 7 20 101 8 135 -17z"/>
<path d="M3506 2341 c-6 -9 -156 -402 -156 -408 0 -2 13 -3 28 -3 26 0 30 5
46 55 l17 56 91 -3 91 -3 17 -50 c14 -42 21 -51 43 -53 15 -2 27 0 27 4 0 4
-35 99 -77 211 -69 178 -81 203 -100 203 -12 0 -24 -4 -27 -9z m62 -157 c17
-48 30 -90 27 -94 -5 -9 -114 -12 -126 -4 -8 5 52 184 61 184 3 0 20 -39 38
-86z"/>
<path d="M3700 2330 c0 -18 7 -20 54 -20 91 0 86 12 86 -196 l0 -184 25 0 25
0 2 188 3 187 63 3 c54 3 62 6 62 22 0 19 -8 20 -160 20 -153 0 -160 -1 -160
-20z"/>
<path d="M4111 2153 c-41 -109 -76 -204 -79 -211 -3 -10 3 -13 23 -10 24 3 31
11 45 53 l17 50 88 3 c98 3 98 3 116 -65 10 -38 15 -43 40 -43 16 0 29 2 29 4
0 2 -27 75 -61 162 -33 88 -68 179 -78 204 -13 35 -22 46 -41 48 -24 3 -29 -7
-99 -195z m136 28 c17 -49 29 -92 25 -95 -8 -9 -132 -7 -132 2 0 13 64 182 70
182 3 0 19 -40 37 -89z"/>
<path d="M3861 4974 l-23 -26 29 -31 c15 -17 220 -225 454 -462 235 -236 494
-499 575 -582 l149 -153 29 25 c15 13 26 28 24 33 -4 12 -1201 1222 -1209
1222 -3 0 -16 -12 -28 -26z"/>
<path d="M2010 4951 c-14 -14 -199 -195 -410 -401 -212 -206 -454 -442 -539
-525 -85 -82 -174 -169 -198 -192 -23 -23 -43 -45 -43 -47 0 -3 12 -17 26 -31
l27 -27 116 114 c64 63 204 200 311 304 107 105 267 260 355 346 88 86 225
219 304 295 l144 139 -18 27 c-21 33 -42 33 -75 -2z"/>
<path d="M893 1954 l-26 -26 609 -609 609 -609 28 29 29 28 -607 607 c-333
333 -608 606 -611 606 -3 0 -17 -12 -31 -26z"/>
<path d="M4790 1747 c-124 -122 -397 -391 -607 -597 -211 -206 -383 -378 -383
-383 0 -10 39 -47 49 -47 9 0 140 127 691 670 282 278 516 507 521 508 24 9
-8 72 -37 72 -5 0 -110 -100 -234 -223z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
